<template>
    <ion-page>
        <ion-content class="ion-padding" style="align-items: -webkit-center; text-align: -webkit-center;top:25%">
            <div
                style="min-height: 256px; position: relative; display: flex; flex-direction: column; align-items: center;">
                <ion-spinner color="warning"
                    style="height: 150px; position: absolute; width: 150px;z-index:1"></ion-spinner>
                <img style="margin-top: 5px; width: 135px; height: 135px; border-radius: 100px; z-index:0"
                    src="./../../resources/animation.gif">
            </div>

            <ion-title style="margin-top:20px">{{ loadingText }}</ion-title>
            <div style="margin-top:20px;font-size: x-large;">Checking for updates...</div>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import { Device } from '@capacitor/device';
import ToolBar from "../components/ToolBar.vue";
import { computed, ref } from "vue";
import {
    IonPage,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonSpinner,
    IonButtons,
    IonSelectOption,
    IonMenuButton,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonTitle,
    IonRow,
    IonCol,
    IonInput,
    IonText,
    IonToast,
    IonSelect,
    IonRadioGroup,
    IonRadio
} from "@ionic/vue";



</script>
<script lang="ts">
import * as outlines from 'ionicons/icons';
let dotCount = 0;
const maxDots = 3;
const loadingText = ref("Loading");

const updateLoadingText = () => {
    dotCount = (dotCount + 1) % (maxDots + 1);
    loadingText.value = "Loading" + ".".repeat(dotCount);
};

let intervalId: any;

export default {
    components: {
        ToolBar
    },
    mounted() {
        intervalId = window.setInterval(updateLoadingText, 500);
    },

    unmounted: () => {
        window.clearInterval(intervalId);
    }
}
</script>

<template>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button menu="nav"></ion-menu-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-menu-button menu="users"><ion-icon :icon="outlines.people"></ion-icon></ion-menu-button>
        </ion-buttons>
        <ion-title>ChapterCore</ion-title>
    </ion-toolbar>
</template>
<script setup lang="ts">
import { Device } from '@capacitor/device';

import { states, stateIds, getState } from "../helper"
import { computed, ref } from "vue";
import {
    IonPage,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonSelectOption,
    IonMenuButton,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonTitle,
    IonRow,
    IonCol,
    IonInput,
    IonToast,
    IonSelect,
    IonRadioGroup,
    IonRadio
} from "@ionic/vue";


</script>
<script lang="ts">
import * as outlines from 'ionicons/icons';

export default {
name: 'ToolBar',
}
</script>
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw, NavigationGuardNext, RouteLocationNormalizedLoaded, RouteLocationNormalized } from 'vue-router';
import { socket, CredentialManager } from "./../globals.js"
const options = {
  checkValidationAndRedirect: (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
    // reject the navigation
    CredentialManager.Validate((e: Function) => {
      if (!e) next({ path: "/login" });
      else next();
    });
  },
  checkNotValidAndRedirect: (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
    // reject the navigation
    CredentialManager.Validate((e: Function) => {
      // if validated and not to route home return route login
      if (e) next({ name: "Room", params: { roomId: "general" } });
      else next();
    });
  },
};
const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/folder/:id',
    component: () => import('../views/FolderPage.vue'),
    beforeEnter: options.checkNotValidAndRedirect
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/signup',
    component: () => import('../views/Signup.vue')
  }, {
    path: '/chat',
    component: () => import('../views/Chat.vue'),
    //beforeEnter: options.checkNotValidAndRedirect
  },
  {
    path: '/logout',
    redirect: '/login',
    beforeEnter: options.checkValidationAndRedirect
  },
  {
    path: '/destroyaccount',
    redirect: '/login',
    beforeEnter: options.checkNotValidAndRedirect
  },
  {
    path: '/datadeletion',
    redirect: '/login',
    beforeEnter: options.checkNotValidAndRedirect
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/login',
  },
  {
    path: '/loading',
    component: () => import('../views/Loading.vue')
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

export default router

// src/backgroundTask.ts
import { Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
const { BackgroundFetch } = Plugins;

export const startBackgroundTask = () => {
    if (Capacitor.isNativePlatform()) {
        // Use BackgroundFetch for native platforms
        BackgroundFetch.configure({
            minimumFetchInterval: 15, // Fetch interval in minutes
        }, async (taskId: string) => {
            console.log('Background task running');
            const response = await fetch('https://api.example.com/data');
            const data = await response.json();
            console.log('Fetched data:', data);

            // Must call finish() when your task is complete
            BackgroundFetch.finish(taskId);
        }, (error: string) => {
            console.error('Background fetch failed', error);
        });
    } else {
        // Use setInterval for web
        setInterval(async () => {
            console.log('Background task running');
            const response = await fetch('https://api.example.com/data');
            const data = await response.json();
            console.log('Fetched data:', data);
        }, 1000);
    }
};